import React, { Component } from 'react';
import './App.css';

class App extends Component {
  render() {
    return (
      <div className="App">
        <div className="backgrounds">
          <img className="background" src="https://res.cloudinary.com/mehrceey/image/upload/v1544634581/fabricsbymercy/PhotoGrid_1544633487025.jpg" alt="" />
          <img className="background" src="https://res.cloudinary.com/mehrceey/image/upload/v1544634574/fabricsbymercy/PhotoGrid_1544633307040.jpg" alt="" />
          <img className="background" src="https://res.cloudinary.com/mehrceey/image/upload/v1544634582/fabricsbymercy/PhotoGrid_1544634025044.jpg" alt="" />
          <img className="background" src="https://res.cloudinary.com/mehrceey/image/upload/v1544634579/fabricsbymercy/PhotoGrid_1544633880951.jpg" alt="" />
          <img className="background" src="https://res.cloudinary.com/mehrceey/image/upload/v1544634577/fabricsbymercy/PhotoGrid_1544633653150.jpg" alt="" />
        </div>

        <div className="overlay">
          <div className="info">
            <h2>Welcome to </h2>
            <h1>Fabrics &nbsp;&nbsp; By &nbsp;&nbsp; Mercy</h1>
            <p>More info, coming soon!</p>

            <div className='contact-icons'>
              <div className='div-icon instagram'>
                <a href='https://instagram.com/fabricsbymercy' className='icons' target='_blank' rel='noopener noreferrer'>
                  <i className='fa fa-instagram'></i>
                </a>
              </div>
              <div className='div-icon envelope'>
                <a href='mailto:fabricsbymercy@gmail.com' className='icons'>
                  <i className='fa fa-envelope'></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
